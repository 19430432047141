// src/components/CTA.js
import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { ctaBg } from "../assets";
import Grow from "@mui/material/Grow";
import { Link } from "react-router-dom";

function CTA() {
  return (
    <Box sx={{ backgroundColor: "primary.main500" }}>
      <Container maxWidth="lg">
        {/* Text Block */}
        <Grow in={true} timeout={1200} appear={true}>
          <Box mt={3}>
            <Typography variant="h4" gutterBottom>
              PLAY ENDLESS GAMES AT Glowfshift
            </Typography>
            <Typography variant="body1" paragraph>
              Welcome to Glowfshift, where the adventure never ends! Discover a
              universe brimming with captivating and endlessly entertaining
              games, ready for you to dive into. No matter your device - be it a
              desktop, smartphone, or tablet - Glowfshift is your go-to gaming
              destination, ensuring seamless play across all platforms. We're
              all about bringing joy to players of every age, with a library
              that's constantly expanding to include new and exciting titles!
            </Typography>
            <Typography variant="body1" paragraph>
              INSTANT PLAY - ZERO HASSLES!
            </Typography>
            <Typography variant="body1" paragraph>
              Forget about long downloads or pesky subscriptions; at Glowfshift,
              it's all about instant access. With a simple click, immerse
              yourself in a world where play knows no bounds. Begin your journey
              with Glowfshift exclusives, such as "Galactic Questers," "Mystic
              Match," "Rapid Racers," and "Shadow Sneak." But that's just the
              start! Venture further to find crowd-pleasers like "Enchanted
              Labyrinths," "Royal Riddles," "Daring Duels," and "Brain Busters,"
              plus many more waiting to be explored!
            </Typography>
            <Typography variant="body1" paragraph>
              Discover other hit titles like: Mystery Maze, Chess Champion, Egg
              Fighters, Quiz of Knowledge, and plenty more!
            </Typography>
            <Typography variant="body1" paragraph>
              BECOME PART OF A GAMING COMMUNITY!
            </Typography>
            <Typography variant="body1" paragraph>
              Looking to make friends while playing? Register an account and
              join games such as Kingdom Farm and Grand Dynasty. These titles
              boast vibrant communities with thousands of players and stand as
              some of the most iconic massive multiplayer online games ever,
              complete with fantastic social features for you to enjoy.
            </Typography>
            <Typography variant="body1" paragraph>
              A GAMING PARADISE WITH DIVERSE CATEGORIES!
            </Typography>
            <Typography variant="body1" paragraph>
              At Glowfshift, diversity is our strength. We proudly host a wide
              array of game types to satisfy any gamer's appetite. Puzzle
              enthusiasts, strategy geniuses, racing aficionados, and action
              seekers will all find something to love. Challenge a friend in our
              multiplayer games, or enjoy a solitary quest in our single-player
              adventures.{" "}
            </Typography>
          </Box>
        </Grow>
      </Container>
    </Box>
  );
}

export default CTA;
