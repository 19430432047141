import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { logo } from "../assets";
import { BiBookmarkHeart } from "react-icons/bi";
import SearchBar from "./SearchBar";

function Header() {
  return (
    <div style={{ height: "72px" }}>
      <AppBar
        position="static"
        style={{
          padding: 4,
          background: "primary.main",
          position: "fixed",
          zIndex: "99",
        }}
      >
        <Toolbar>

          <Box
            className="logo"
            sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
                    <SearchBar />

            <Link
              to="/"
              style={{
                textDecoration: "none",
                color: "#E9EAEC",
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <img
                src={logo}
                alt="brand logo"
                style={{ maxWidth: "70px", width: "100%", height: "auto" }}
              />
            
            </Link>

            <Link
              to="/myList"
              style={{
                textDecoration: "none",
                color: "#E9EAEC",
                display: "flex",
                alignItems: "center",
                gap: ".5rem",
              }}
            >
              <Typography
                className="my-list-link"
                sx={{
                  display: {
                    xs: "none",
                    md: "block",
                  },
                }}
              >
                Your games
              </Typography>
              <Typography
                className="my-list-link"
                sx={{
                  display: {
                    xs: "block",
                    md: "none",
                  },
                }}
              >
                <BiBookmarkHeart fontSize="2rem"/>
              </Typography>
            </Link>
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Header;
