// src/components/Hero.js
import React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { hero } from "../assets";
import Grow from "@mui/material/Grow";

function Hero() {
  return (
    <Box
      padding="4rem 10px"
      display="flex"
      flexDirection="column"
      style={{
        backgroundColor: "#333652",
        backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, .5) 50%, rgba(0, 0, 0, .5) 100%), url(${hero})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "bottom",
      }}
    >
        <Grow in={true} timeout={1200}>
          <Box p={4} display="flex" height="100%" flexDirection="column" justifyContent="center" alignItems="center" textAlign="left">
            <Typography variant="h3" gutterBottom style={{ fontWeight: "900" }}>
             Online games
            </Typography>
          </Box>
        </Grow>
    </Box>
  );
}

export default Hero;
