import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Form from "./Form";
import { Link } from 'react-router-dom';
import { TbMailHeart } from "react-icons/tb";
import { BsEmojiWink } from "react-icons/bs";



export default function Contacts() {
  return (
    <div style={{padding: "0 1rem"}}>
    <Container maxWidth="lg" id="contacts" style={{background: "#141621", textAlign: "center", margin: "5rem auto"}}>
      <Typography variant="h4" gutterBottom>Get in touch</Typography>
      <Typography variant="body1" gutterBottom>Have any suggestions or questions?</Typography>
      <Typography variant="body1" gutterBottom>We'd love to hear from you! <TbMailHeart style={{verticalAlign: "middle", fontSize: "2rem"}} />
</Typography>
      <Typography variant="body1" gutterBottom>Send us a message and we'll get back to you as soon as possible:
    </Typography>
    <Form/>
    </Container>
    </div>
  )
}
